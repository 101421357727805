import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import './testimonial-slider.scss'; 
import { CardQuote } from "../CardQuote/CardQuote";

const TestimonialsSlider = ({ testimonials }) => {
  return (
    <section className="testimonials-slider">
      <div className="testimonials-slider__container">
        <Swiper
          modules={[Navigation, Pagination]}  // Ensure the Pagination and Navigation modules are loaded
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ 
            clickable: true, // Pagination bullets should be clickable
            el: ".swiper-pagination", // Make sure Swiper targets the correct pagination element
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <CardQuote
                fullName={testimonial.fullName}
                image={testimonial.image}
                position={testimonial.position}
                citation={testimonial.citation}
                insideSlider
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TestimonialsSlider;
