import React from "react";
import Layout from "../../components/layout/layout";
import CtaSection from "../../components/CtaSection/CtaSection";
import { Container } from "../../components/Container/Container";
import { Column } from "../../components/Column/Column";
import { graphql, useStaticQuery } from "gatsby";
import { PostFold } from "../../components/PostFold/PostFold";
import { CardMockup } from "../../components/CardMockup/CardMockup";
import { TechItem } from "../../components/TechItem/TechItem";
import TestimonialsSlider from "../../components/TestimonialsSlider/TestimonialsSlider";



const IMAGE_QUERY = graphql`
  {
    projectImage: file(
      relativePath: { eq: "our-work/jakob-muller-project-wide.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
      publicURL  
    }
    jmMockup: file(
      relativePath: { eq: "our-work/jakob-muller/mymunet-mockup.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconPostgreSql: file(relativePath: { eq: "technologies/postgreql.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconClickHouse: file(relativePath: { eq: "technologies/clickhouse.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconGrpc: file(relativePath: { eq: "technologies/grpc.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconReact: file(relativePath: { eq: "technologies/react.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconWebsockets: file(relativePath: { eq: "technologies/websockets.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconTypescript: file(relativePath: { eq: "technologies/typescript.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconNodeJs: file(relativePath: { eq: "technologies/nodejs.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconSps: file(relativePath: { eq: "technologies/siemens-sps.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    iconOpcUa: file(relativePath: { eq: "technologies/opc-ua.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    imageJerko: file(relativePath: { eq: "people/nikola-jerkovic.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
  }
`;

const OurWorkMymunet = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);


  const testimonialData = [
    {
      fullName: "Nikola Jerković",
      image: tempImage.imageJerko, 
      position: "Lead developer at Kickstage",
      citation:
        "Developing MyMünet for Jakob Müller Group was a fantastic opportunity to push the boundaries of IoT in manufacturing. The challenges we overcame in integrating diverse machine types and managing vast amounts of data have honed our skills and expanded our capabilities. We're proud to have created a solution that not only meets current needs but is also poised for future innovations.",
    },
  ];

  return (
    <Layout
      pageTitle="Kickstage Develops MyMünet  - An Advanced IoT Platform for Jakob Müller Group"
      description="DutyDingo, a startup founded by two seasoned software development agency owners, approached us with a vision to revolutionize project management in the tech industry. Their goal was to streamline the often cumbersome process of creating tickets from chat conversations and managing project information more efficiently."
      image={tempImage.projectImage.publicURL} 
    >
      <PostFold
        title="<strong>Kickstage Develops</strong> </br> MyMünet  - An Advanced IoT Platform for Jakob Müller Group"
        image={tempImage.projectImage}
        backLinkUrl="/our-work"
        backLinkTitle="Our Work"
        wrapHeading
      />

      <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12">
          <div className="blog-post">
            <div className="blog-post__description">
              <p>
                <a
                  href="https://www.mueller-frick.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Jakob Müller Group
                </a>
                , a renowned Swiss company founded in 1887, has been at the
                forefront of the textile industry forover a century.
                Specializing in the production of machines for high-quality and
                technical textiles, their equipment is crucial in manufacturing
                products for diverse sectors including medical, automotive, and
                fashion industries. Their innovative machinery is used to create
                everything from intricate lace trimmings for haute couture to
                highly specialized textiles for medical implants and automotive
                safety systems.
              </p>
            </div>
            <div className="blog-post__main-holder">
              <p>
                With a global presence spanning multiple continents and a
                diverse product range including weaving, finishing, anddyeing
                machines,{" "}
                <a
                  href="https://www.mueller-frick.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Jakob Müller Group
                </a>{" "}
                has consistently pushed the boundaries of textile manufacturing
                technology.However, recognizing the growing importance of
                digital solutions in modern manufacturing, the company sought
                tomodernize their operations and provide enhanced value to their
                customers through digital transformation. This initiative was
                not just about improving their own processes, but also about
                enabling their clients across various industries to meet the
                increasing demands for precision, efficiency, and traceability
                in textile production.
              </p>

              <h2>The Challenge</h2>
              <p>
                Despite their long-standing expertise in machinery, Jakob Müller
                Group faced a significant challenge in the digital age.Their
                clients lacked connectivity with their machines, resulting in
                inefficient data gathering and remote monitoringcapabilities.
                Complex orders required manual intervention, with operators
                physically walking up to machines with flashdrives to input
                instructions. This outdated process hindered productivity and
                limited the potential for data-driven decision making.
              </p>
              <h2>Kickstage's Solution: MyMünet</h2>
              <strong>Key features of MyMünet include:</strong>
              <p>
                To address these challenges, we jointly developed MyMünet, a
                sophisticated IoT platform that revolutionized how Jakob Müller
                Group and their clients interact with their machinery. MyMünet
                is a multi-tenant solution that securely connects a large number
                of machines to a private cloud infrastructure, enabling
                comprehensive data analysis and machine management.
              </p>
              <ul>
                <li>
                  Real-time analysis of machine data, including motor speeds and
                  temperatures
                </li>
                <li>
                  High-level efficiency monitoring for entire production sites
                </li>
                <li>
                  Article management and remote order transmission to machines
                </li>
                <li>Custom dashboards for tailored data insights</li>
                <li>
                  Environmental impact assessment, including CO₂ output
                  calculations based on yarn data and electrical consumption
                </li>
                <li>
                  A modern, well-documented GraphQL API for seamless integration
                  and extensibility
                </li>
              </ul>

              <CardMockup image={tempImage.jmMockup} />
              
              <h2>Technical Implementation</h2>
              <strong>
                We leveraged a robust tech stack to build MyMünet:
              </strong>
              <ul>
                <li>
                  A cluster of PostgreSQL and later Clickhouse databases for
                  scalable storage of billions of data points
                </li>
                <li>
                  Protobuf and gRPC for efficient and reliable communication
                  between field machines and the cloud solution
                </li>
                <li>
                  React, Websockets, TypeScript, and NodeJS for a real-time
                  frontend with advanced charting capabilities
                </li>
                <li>
                  OPC/UA and Siemens S7 protocols for broader machine
                  connectivity
                </li>
              </ul>
              <div className="grid-holder">
                <TechItem title="PostgreSQL" image={tempImage.iconPostgreSql} />
                <TechItem title="ClickHouse" image={tempImage.iconClickHouse} />
                <TechItem title="gRPC" image={tempImage.iconGrpc} />
                <TechItem title="React" image={tempImage.iconReact} />
                <TechItem title="Websockets" image={tempImage.iconWebsockets} />
                <TechItem title="TypeScript" image={tempImage.iconTypescript} />
                <TechItem title="Node.JS" image={tempImage.iconNodeJs} />
                <TechItem title="Siemens SPS/S7" image={tempImage.iconSps} />
                <TechItem image={tempImage.iconOpcUa} imageFullWidth />
              </div>
              <h2>Project Timeline and Evolution</h2>
              <p>
                The MyMünet project has been an ongoing success story for over
                five years. Our agile approach allowed for the releaseof a
                functional prototype within just a few months, quickly
                demonstrating value to Jakob Müller Group. Since then,
                theplatform has seen continuous improvement with regular
                bi-monthly updates. Notable milestones include the integration
                of additional machine types connected via the Siemens S7
                protocol and MODBUS, expanding the platform's versatility and
                reach.
              </p>
              <h2>Results and Impact</h2>
              <strong>
                The implementation of MyMünet has transformed operations for
                Jakob Müller Group and their clients:
              </strong>
              <ul>
                <li>
                  Enhanced remote access to machine data, improving production
                  flows and decision-making
                </li>
                <li>
                  Streamlined order management, replacing manual processes with
                  a user-friendly web interface
                </li>
                <li>
                  Improved compliance with Industry 4.0 requirements through
                  GraphQL and OPC/UA API interfaces
                </li>
                <li>
                  Enhanced environmental awareness through CO₂ output
                  calculations
                </li>
                <li>
                  Increased operational efficiency and reduced downtime through
                  real-time monitoring and analytics
                </li>
              </ul>
              <TestimonialsSlider testimonials={testimonialData} />


              <h2>Conclusion</h2>
              <p>
                The MyMünet project showcases our ability to deliver complex,
                scalable IoT solutions that drive digital transformation
                intraditional industries. By combining cutting-edge technologies
                with a deep understanding of client needs, we havecreated a
                platform that not only solves immediate challenges but also
                opens up new possibilities for innovation andgrowth in the
                textile manufacturing sector.{" "}
              </p>
            </div>
          </div>
        </Column>
      </Container>

      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
    </Layout>
  );
};

export default OurWorkMymunet;
