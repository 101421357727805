import React from "react";
import "./tech-item.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const TechItem = ({ title, image, imageFullWidth }) => {
  const parsedImage = getImage(image);
  return (
    <div className={`tech-item ${imageFullWidth ? "tech-item--image-full" : ""}`}>
      {image && 
        <div className="tech-item__image">
          <GatsbyImage image={parsedImage} alt={title} className="image" />
        </div>
      }
      <div className="tech-item__title">
        <p className="title">{title}</p>
      </div>
    </div>
  );
};
